<template>
    <div>
      <navbar
        :items="navbarItems"
        :fonticon="pageIcon"
        :navbartitle="pageTitle"
        :navbardescription="pageDescription"
      />
      <data-table
        :data-source="factures"
        :headers="headers"
        table-class="table-striped table-hover"
        >
            <template slot="actions">
                <a
                    @click.prevent="initForm"
                    data-toggle="modal"
                    data-target="#add-facture"
                    class="btn btn-outline-primary btn-icon"
                >
                    <i class="icofont icofont-plus" />
                </a>
            </template>
        </data-table>
        <modal
            id="add-facture"
            title="Ajouter une nouvelle facture"
            :footer="false"
        >
        <form
            class="form-horizontal"
            @submit.prevent="createBill"
        >
            <div class="row">
                <div class="col-12" v-if="has_error">
                    <p class="alert aler-danger">{{ error_msg }}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label for="user">Destinataire</label>
                        <select class="form-control" v-model="user" name="user" id="user" v-select="{placeholder: 'Selecctionnez le client'}">
                            <option></option>
                            <option v-for="p in sortedProprietaires" :key="p.uid" :value="p.uid">{{ p.fullname }}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label for="description">Description</label>
                        <textarea v-model="description" class="form-control" name="description" id="description" rows="5"></textarea>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <div class="form-group text-right">
                        <button type="submit" class="btn btn-primary btn-sm">Ajouter</button>
                    </div>
                </div>
            </div>
            </form>
        </modal>
    </div>
  </template>
  
  <script>
import Navbar from "../../components/navbar.vue"
import DataTable from '../../components/dataTable/local.vue'
import Modal from '../../components/modal.vue'
import { mapGetters, mapMutations } from "vuex"
import { ADD_FACTURE } from "../../graphql/market"
import { COMPONENT_TYPE, TEXT_TYPE } from "../../components/dataTable/dataType"
const Actions = () => import('../../components/market/facture/actions.vue')
  export default {
      components: {Navbar, DataTable, Modal},
      data(){
          return {
            navbarItems: [
              {
                libelle: 'Market Place',
                route: 'market-products'
              }, {
                libelle: 'Facturation'
              }
            ],
            pageIcon: 'la-cart',
            pageTitle: 'Facturation',
            pageDescription: 'Espace de facturation sur CASEAC',
            has_error: false,
            error_msg: null,
            user: null,
            description: null
          }
      },
      methods: {
        ...mapMutations({
            done: 'DONE',
        }),
        initForm(){
            this.user = null
            this.description = null
            this.has_error = false
            this.error_msg = null
        },
        async createBill(){
            let data =  {
                user: this.user,
                description: this.description
            }
            try {
                const response = await this.$apollo.mutate({
                    mutation: ADD_FACTURE,
                    variables: {
                        facture: { ...data }
                    },
                    update: (cache, { data: { addFacture } }) => {
                        console.log(addFacture);
                        console.log(`Facture added successfully with uid ${addFacture}`);
                    }
                });
                this.done();
                this.initForm();
                setTimeout(() => this.$router.push({ name: 'market-billing-detail', params: { uid: response.data.addFacture } }), 1000)
                
            } catch (error) {
                console.error(error);
                this.has_error = true;
                this.error_msg = error.message || 'An error occurred while adding the facture.';
            }
        }
      },
      computed: {
        ...mapGetters({
            factures: 'market/factures',
            proprietaires: 'auth/proprietaires',
            is_super_admin: 'auth/is_super_admin',
        }),
        sortedProprietaires() {
            return [...this.proprietaires].sort((a,b) => a.fullname.localeCompare(b.fullname))
        },
        headers(){
            return [
                {label: 'UID', name: 'uid', type: TEXT_TYPE, visible: this.is_super_admin},
                {label: 'Numero Facture', name: 'invoiceNumber', type: TEXT_TYPE},
                {label: 'Destinataire', name: 'user', type: TEXT_TYPE},
                {label: 'Montant', name: 'amount', type: TEXT_TYPE},
                {label: 'Etat', name: 'status', type: TEXT_TYPE},
                {label: 'Reférence de payment', name: 'reference', type: TEXT_TYPE},
                {label: 'Actions', name: 'action', type: COMPONENT_TYPE, component: Actions}
            ]
        }
      }
  }
  </script>
  
  <style>
  
  </style>